<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup>
</script>

<style scoped>
div {
  box-shadow: 0px 3px 7px var(--b4);
  background-color: #ffffff;
  border-radius: 10px;
}
</style>