<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup>
</script>

<style scoped>
div {
  border-radius: 15px;
  color: var(--p3);
  box-shadow:inset 0px 3px 10px var(--b4);
}
</style>